<template>
  <b-card
    no-body
  >
    <b-row>
      <b-col
        md="12"
        lg="12"
      >
        <div class="d-sm-flex justify-content-between align-items-center">
          <h4 class="card-title mt-1 ml-1">
            <span
              v-html="survey.question.statement"
            />
          </h4>
        </div>

        <!-- chart -->
        <vue-apex-charts
          :ref="survey.ID"
          :options="chartOptions"
          :series="chartSeries"
          type="bar"
          height="350"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol } from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import Ripple from 'vue-ripple-directive'
import constants from '@/constants/static.json'
import { integer } from '@/@core/utils/validations/validations'

export default {
  components: {
    VueApexCharts,
    BCard,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    index: {
      type: integer,
      default: () => 0,
    },
  },
  data() {
    return {
      chartOptions: {
        chart: {
          type: 'bar',
          toolbar: {
            show: true,
            tools: {
              download: false,
            },
          },
        },
        xaxis: {
          categories: [],
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
          },
        },
        colors: [constants.CHART_COLORS[this.index]],
        dataLabels: {
          enabled: true,
          position: 'top',
          formatter(val) {
            return `${val}%`
          },
        },
      },
      chartSeries: [
        {
          name: 'Percentage',
          data: [],
        },
      ],
      survey: {
        ID: 0,
        question: {
          statement: '',
          survey_questions: [],
          survey_question_answers: [],
        },
      },
    }
  },
  watch: {
    data: {
      immediate: true,
      handler() {
        if (this.data) {
          this.survey = { ...this.data }
          const xAxis = this.extractMinMaxValues()
          const yAxis = this.processChartData(this.survey.question.survey_question_answers, xAxis)
          this.chartSeries[0].data = yAxis
          this.updateAxis(xAxis)
        }
      },
    },
  },
  methods: {
    updateAxis(data) {
      this.chartOptions = {
        ...this.chartOptions,
        xaxis: {
          categories: data,
        },
      }
    },
    extractMinMaxValues() {
      const { question } = this.data
      const minimumValue = question.minimum_value
      const maximumValue = question.maximum_value

      // eslint-disable-next-line no-restricted-globals
      if (!isNaN(minimumValue) && !isNaN(maximumValue)) {
        // Generate an array of categories from minimum to maximum
        const categories = Array.from({ length: maximumValue - minimumValue + 1 }, (_, index) => (minimumValue + index).toString())

        return categories
      }

      return []
    },
    processChartData(surveyData, validRatings) {
      // Initialize an array to store percentages
      const percentageData = Array(validRatings.length).fill(0)

      // Calculate percentages for the filtered data
      const totalCount = surveyData.length
      validRatings.forEach((rating, index) => {
        const count = surveyData.filter(entry => entry.answer === rating).length
        const percentage = (count / totalCount) * 100
        percentageData[index] = parseFloat(percentage.toFixed(2))
      })

      return percentageData
    },
  },
}
</script>

<template>
  <div>
    <div
      v-if="data"
      class="blog-detail-wrapper"
    >
      <b-row>
        <!-- Survey -->
        <b-col cols="12">
          <b-card>
            <h4 class="card-title">
              <span v-html="data.statement" />
            </h4>
            <!-- eslint-enable -->
            <hr class="my-2">
            <span
              v-for="(
                questionAnswer, index
              ) in data.survey_question_answers"
              :key="index"
            >
              <template v-if="isURL(questionAnswer.answer)">
                <a
                  :href="questionAnswer.answer"
                  target="_blank"
                >
                  {{ questionAnswer.answer }}
                </a>
              </template>
              <template v-else>
                {{ displayAnswer(questionAnswer.answer) }}
              </template>
              <!-- Add a comma if not the last element -->
              <span v-if="index < data.survey_question_answers.length - 1">, </span>
            </span>
          </b-card>
        </b-col>
        <!--/ Survey -->
      </b-row>
    </div>
  </div>
</template>
<script>
import Ripple from 'vue-ripple-directive'
import {
  BRow,
  BCol,
  BCard,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-extraneous-dependencies
import { formatDate, avatarText } from '@core/utils/filter'
import { mixinList } from '@/constants/mixinValidations'

export default {
  directives: {
    Ripple,
  },
  components: {
    BRow,
    BCol,
    BCard,
  },
  mixins: [mixinList],
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    isEmailShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      URL_REGEX: /^(ftp|http|https):\/\/[^ "]+$/,
      // eslint-disable-next-line no-useless-escape, comma-dangle
      EMAIL_REGEX: /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/g
    }
  },
  setup() {
    return {
      formatDate,
      avatarText,
    }
  },
  methods: {
    isURL(text) {
      return this.URL_REGEX ? new RegExp(this.URL_REGEX).test(text) : false
    },
    displayAnswer(data) {
      if (this.isEmailShow) {
        return data
      }
      return data.replace(this.EMAIL_REGEX, '***')
    },
  },
}
</script>

<style lang="scss" scoped>
.assignee-selector {
  ::v-deep .vs__dropdown-toggle {
    padding-left: 0;
  }
}

.draggable-task-handle {
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  visibility: hidden;
  cursor: move;

  .todo-task-list .todo-item:hover & {
    visibility: visible;
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import "~@core/scss/base/components/variables-dark";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.form-item-section {
  background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: 0.35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }
  }
}
</style>

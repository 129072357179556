<template>
  <b-card
    v-if="survey.question.survey_question_answers.length>0"
    no-body
    class="card-revenue-budget"
  >
    <b-row>
      <b-col
        md="12"
        lg="12"
        sm="12"
      >
        <div class="d-sm-flex justify-content-between align-items-center">
          <h4 class="card-title mb-0 mt-1 ml-1">
            <span v-html="survey.question.statement" />
          </h4>
        </div>
        <!-- chart -->
        <div>
          <div>
            <vue-apex-charts
              height="200"
              :options="chartOptions"
              :series="chartSeries"
            />
          </div>
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import { BCard, BRow, BCol } from 'bootstrap-vue'
import constants from '@/constants/static.json'

export default {
  components: {
    VueApexCharts,
    BCard,
    BRow,
    BCol,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      survey: {
        ID: 0,
        question: {
          statement: '',
          survey_questions: [],
          survey_question_answers: [],
        },
      },
      chartOptions: {
        labels: [],
        colors: [],
        chart: {
          width: '100%',
          type: 'donut',
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: '100%',
              },
              legend: {
                position: 'bottom',
              },
            },
          },
        ],
        dataLabels: {
          enabled: false, // Disables data labels on the chart
        },
      },
      chartSeries: [],
    }
  },
  watch: {
    data: {
      immediate: true,
      handler() {
        if (this.data) {
          this.survey = { ...this.data }
          const xAxis = this.extractXAxis()
          const yAxis = this.extractYAxis(
            this.survey.question.survey_question_answers,
            xAxis,
          )
          const displayXData = this.displayExtractXAxis(
            this.survey.question.survey_question_answers,
            xAxis,
          )
          this.chartSeries = yAxis
          this.updateAxis(displayXData)
        }
      },
    },
  },
  methods: {
    extractXAxis() {
      return this.data.question.question_answers.map(
        answerObject => answerObject.answer,
      )
    },
    displayExtractXAxis(surveyData, xAxis) {
      const displayXData = Array(xAxis.length).fill(0)

      // Calculate the total count
      const total = surveyData.length

      if (total === 0) {
        console.warn('No data available.') // Handle the case where there's no data
        return displayXData
      }

      xAxis.forEach((answer, index) => {
        const count = surveyData.filter(
          entry => entry.answer === answer,
        ).length
        let percentage = (count / total) * 100
        // eslint-disable-next-line no-mixed-operators
        percentage = percentage.toFixed(0)
        displayXData[index] = `${answer} ${percentage}%`
      })
      return displayXData
    },
    extractYAxis(surveyData, xAxis) {
      // Initialize an array to store percentages
      const yData = Array(xAxis.length).fill(0)

      // Calculate the total count
      const totalCount = surveyData.length

      if (totalCount === 0) {
        console.warn('No data available.') // Handle the case where there's no data
        return yData
      }

      xAxis.forEach((answer, index) => {
        const count = surveyData.filter(
          entry => entry.answer === answer,
        ).length
        yData[index] = (count / totalCount) * 100
      })
      return yData
    },
    updateAxis(data) {
      this.chartOptions = {
        ...this.chartOptions,
        labels: data,
        colors: constants.CHART_COLORS.slice(0, data.length),
      }
    },
  },
}
</script>
<style scoped>
/* Base styles for the container */
.container {
  display: block; /* Stack items vertically by default */
  width: 100%; /* Full width of the parent element */
  padding: 20px; /* Spacing around the container */
  box-sizing: border-box; /* Padding included in the width */
  overflow-x: hidden; /* Prevent horizontal scroll */
}

/* Styles for the chart container, taking full width on mobile */
.chart-container {
  width: 100%;
  max-width: 100%; /* Ensure it doesn't exceed the container's width */
  margin-bottom: 10px; /* Space between chart and text */
}

/* Styles for the graph text on mobile */
.graph-text {
  width: 100%; /* Full width on mobile to sit below the chart */
  text-align: center; /* Center the text on mobile */
  font-size: 14px; /* Optimal font size for mobile readability */
}

/* Responsive adjustments for larger screens */
@media (min-width: 768px) { /* Adjusts for screens larger than mobile */
  .container {
    display: flex; /* Use flexbox for horizontal layout on larger screens */
    justify-content: space-around; /* Space out items */
    padding: 20px; /* Larger padding on larger screens */
  }

  .chart-container {
    width: 40%; /* Chart takes up 40% width on larger screens */
    margin-bottom: 0; /* No space needed between chart and text on larger screens */
  }

  .graph-text {
    width: 60%; /* Text takes up the remaining space */
    text-align: left; /* Align the text to the left on larger screens */
    font-size: 16px; /* Larger font size for desktops */
  }
}
</style>

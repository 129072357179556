<template>
  <div>
    <div class="blog-detail-wrapper">
      <b-row>
        <!-- Survey -->
        <b-col cols="12">
          <b-card
            :title="survey.name"
          >
            <b-media no-body>
              <b-media-aside
                vertical-align="center"
                class="mr-50"
              >
                <feather-icon
                  icon="TwitchIcon"
                  size="24"
                  class="mr-50 text-primary"
                />
              </b-media-aside>
              <b-media-body>
                <small class="text-muted">{{ survey.survey_status }}</small>
                <span class="text-muted ml-75 mr-50">|</span>
                <small class="text-muted">{{ scopeName }}</small>
                <span class="text-muted ml-75 mr-50">|</span>
                <small class="text-muted">{{ survey.frequency }}</small>
                <span class="text-muted ml-75 mr-50">|</span>
                <small class="text-muted">{{ formatDate(survey.start_date) }}</small>
              </b-media-body>
            </b-media>
            <div
              v-if="category"
              class="my-1 py-25"
            >
              <b-badge
                pill
                class="mr-75"
                variant="light-primary"
              >
                {{ category.name }}
              </b-badge>
            </div>
            <div v-if="isDisplayQR">
              <p class="mt-1">
                {{ $t('SCAN_SURVEY_QR_CODE') }}
              </p>
              <qrcode-vue

                :value="invitationLink"
                size="125"
                level="L"
                foreground="#EE6E25"
              />
            </div>
            <!-- eslint-disable vue/no-v-html -->
            <div
              class="blog-content  mt-2"
              v-html="survey.description"
            />
            <!-- eslint-enable -->
            <hr class="my-2">
            <div class="d-flex align-items-center justify-content-between">
              <div class="d-flex align-items-center">
                <div class="d-flex align-items-center mr-1">
                  <b-link class="mr-50">
                    <feather-icon
                      icon="UserIcon"
                      size="21"
                      class="text-body"
                    />
                  </b-link>
                  <b-link>
                    <div class="text-body">
                      {{ survey.total_users }}
                    </div>
                  </b-link>
                </div>
                <div class="d-flex align-items-center">
                  <b-link class="mr-50">
                    <feather-icon
                      size="21"
                      icon="UserCheckIcon"
                      class="text-body"
                    />
                  </b-link>
                  <b-link>
                    <div class="text-body">
                      {{ survey.total_responses }}
                    </div>
                  </b-link>
                </div>
              </div>
            </div>
          </b-card>
        </b-col>
        <!--/ Survey -->
      </b-row>
    </div>
  </div>
</template>
<script>
import Ripple from 'vue-ripple-directive'
import {
  BRow,
  BCol,
  BCard,
  BLink,
  BMedia,
  BMediaAside,
  BBadge,
  BMediaBody,
} from 'bootstrap-vue'
import QrcodeVue from 'qrcode.vue'
// eslint-disable-next-line import/no-extraneous-dependencies
import { formatDate, avatarText } from '@core/utils/filter'
import { mixinList } from '@/constants/mixinValidations'
import constants from '@/constants/static.json'

export default {
  directives: {
    Ripple,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BMediaBody,
    BMedia,
    BBadge,
    BMediaAside,
    BLink,
    QrcodeVue,
  },
  mixins: [mixinList],
  props: {
    survey: {
      type: Object,
      default: () => {},
    },
    category: {
      type: Object,
      default: () => {},
    },
    isDisplayQR: {
      type: Boolean,
      default: () => false,
    },
    invitationLink: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      scopeOptions: constants.SCOPE,
    }
  },
  computed: {
    scopeName() {
      const scope = this.scopeOptions.find(item => item.id === this.survey.is_private)
      return scope ? scope.name.toLowerCase() : ''
    },
  },
  setup() {
    return {
      formatDate,
      avatarText,
    }
  },
}
</script>

<style lang="scss" scoped>
.assignee-selector {
  ::v-deep .vs__dropdown-toggle {
    padding-left: 0;
  }
}

.draggable-task-handle {
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  visibility: hidden;
  cursor: move;

  .todo-task-list .todo-item:hover & {
    visibility: visible;
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import "~@core/scss/base/components/variables-dark";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.form-item-section {
  background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: 0.35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }
  }
}
</style>
